import React from "react"
import styled from "styled-components"

const MessageContainer = styled.div`
  background: #feefb3;
  border: 1px solid black;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
`

export default function MessagePanel({ message }) {
  if (message == null || message === "") {
    return null
  }

  return <MessageContainer dangerouslySetInnerHTML={{__html: message}} />
}
