import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/Layout"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import LinkCustom from "../components/atoms/LinkCustom"
import { getTranslation } from "../utils/LanguagesUtil"
import MessagePanel from "./atoms/MessagePanel"
import { HOMEPAGE_MESSAGE } from "../utils/Config"

const ArticleEntry = styled.article`
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 2px double #f3f3f3;
`

const ArticleHeaderWrapper = styled.header`
  font-size: 0.75rem;
  margin-bottom: 1rem;
`

const ArticleHeaderTitle = styled.h2`
  margin-bottom: 0.5rem;
`

const ArticleHeaderMeta = styled.div``

const ArticleHeader = ({ node }) => (
  <ArticleHeaderWrapper className="entry-header">
    <ArticleHeaderTitle>
      <LinkCustom to={node.fields.slug} rel="bookmark">{node.frontmatter.title}</LinkCustom>
    </ArticleHeaderTitle>
    <ArticleHeaderMeta>
      {getTranslation("by")} Rafał Pydyniak | {node.frontmatter.date}
    </ArticleHeaderMeta>
  </ArticleHeaderWrapper>
)

const ExcerptWrapper = styled.p``

const ArticleCoverImageWrapper = styled.div`
  width: 200px;
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
`

const ReadMoreWrapper = styled.span``

const ArticleBody = ({ node }) => (
  <div className="entry-summary">
    <ArticleCoverImageWrapper>
      <Img fadeIn={false} alt="" fixed={node.frontmatter.coverImage?.childImageSharp?.fixed} />
    </ArticleCoverImageWrapper>
    <ExcerptWrapper>
      {node.frontmatter.excerpt ?? node.excerpt}&nbsp;
      <ReadMoreWrapper>
        <Link
          to={node.fields.slug}
          css={css`
            text-decoration: none;
          `}
        >
          {getTranslation("read_more")} »
        </Link>
      </ReadMoreWrapper>
    </ExcerptWrapper>
  </div>
)

const ArticleFooterWrapper = styled.footer`
  clear: both;
  font-size: 0.75rem;
`

const ArticleFooter = ({ node }) => (
  <ArticleFooterWrapper className="entry-meta">
    {getTranslation("categories")}:{" "}
    {node.frontmatter.categories.map(category => (
      <LinkCustom
        key={category}
        to={"/category/" + category}
        rel={"category tag"}
        style={{ padding: "0.15rem" }}
      >
        {category}
      </LinkCustom>
    ))}
  </ArticleFooterWrapper>
)

export default function ArticleList({ data }) {
  return (
    <Layout>
      <MessagePanel message={HOMEPAGE_MESSAGE}/>
      <div id="content">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <ArticleEntry key={node.id}>
            <ArticleHeader node={node} />
            <ArticleBody node={node} />
            <ArticleFooter node={node} />
          </ArticleEntry>
        ))}
      </div>
    </Layout>
  )
}